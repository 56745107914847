import React, { useState } from 'react';
import { getAuth, signOut } from 'firebase/auth';
import { Grid, Container, Button } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import Modal from '../Modal';
import video from '../../resources/videos/videoTutorial.mp4';
import Connect from '../../resources/images/footer/spira-connect.png';
import linkedin from '../../resources/images/footer/linkedin.png';
import instagram from '../../resources/images/footer/instagram.png';
import workplace from '../../resources/images/footer/workplace.svg';
import './styles.scss';

const date = new Date().getFullYear();

export default function Footer() {
	const navigate = useNavigate();
	const [modal, setModal] = useState(false);

	const logout = () => {
		const auth = getAuth();
		signOut(auth)
			.then(() => {
				// Sign-out successful.
				window.localStorage.removeItem('0hNZeY/gjEeJihP9bIPGHw==');
				navigate('/');
			})
			.catch((error) => {
				console.log('error: ' + error);
				// An error happened.
			});
	};
	return (
		<>
			<div id='footer'>
				{localStorage.getItem('0hNZeY/gjEeJihP9bIPGHw==') !== null && (
					<Container maxWidth='lg'>
						<Grid container direction='row' alignItems='center' justifyContent='center'>
							<Grid item xs={12} sm={2} md={6} lg={4}>
								<div className='logo-connect'>
									<img src={Connect} alt='img' className='img-connect' />
								</div>
							</Grid>
							<Grid item xs={12} sm={8} md={3} lg={4} className='center'>
								<Grid container direction='row' alignItems='center' justifyContent='center'>
									<Grid item xs={12} sm={12} md={12} lg={12}>
										<div className='titulo-menu bold'>Spira Connect</div>
									</Grid>
									<ul>
										<li>
											<div className='option' onClick={() => navigate('/home')}>
												Caring
											</div>
										</li>
										<li>
											<div className='option' onClick={() => navigate('/pilares')}>
												Daring
											</div>
										</li>
									</ul>
								</Grid>
							</Grid>
							<Grid item xs={12} sm={2} md={3} lg={4}>
								<div className='out-sesion'>
									<Button type='submit' className='btn btn-one' onClick={logout}>
										Cerrar sesión
									</Button>
								</div>
							</Grid>
						</Grid>
					</Container>
				)}
				<div className='divider' />
				<Container maxWidth='lg'>
					<Grid container direction='row' alignItems='center' justifyContent='center' spacing={2}>
						<Grid item xs={12} sm={8} md={9} lg={9}>
							<div className='all-rights-reserved'>&copy; {`Derechos reservados, Spira ${date}`}</div>
						</Grid>
						<Grid item xs={12} sm={4} md={3} lg={3}>
							<div className='social-media'>
								<div className='follow-us padre'>
									<span className='hijos'>Síguenos en</span>
									<img src={workplace} alt='img' className='hijos cursor' onClick={() => window.open('https://work.me/g/3BHX44dXY/DAqCZJZ8', '_blank')} />
									{/* <img src={facebook} alt="img" className="hijos cursor" onClick={() => window.open("", "_blank")} /> */}
									{/* <img src={instagram} alt="img" className="hijos cursor" onClick={() => window.open("https://instagram.com/mindfulcare.mx?igshid=YmMyMTA2M2Y=", "_blank")} /> */}
								</div>
							</div>
						</Grid>
					</Grid>
				</Container>
			</div>
		</>
	);
}
