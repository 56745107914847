import React, { useState, useEffect } from "react";
// import { ref, onChildChanged } from "firebase/database";
// import { db } from "../../../config/firebase";
import { Grid, Container, TextField, Button } from "@material-ui/core";
import Swal from "sweetalert2";
import ValidateUser from "../../../components/ValidateUser";
import Header from "../../../components/HeaderTestiminios";
import Footer from "../../../components/Footer";
import uniqid from 'uniqid';
// import orderby from 'lodash.orderby';

import { getCollections, setData, top } from '../../../resources/js/functions';
import Carousel from "./Carousel";
import './styles.scss';

export default function SectionConferencetoday() {
  const [testimonioForm, setTestimonioForm] = useState({});

  useEffect(() => {
    top();
    localStorage.removeItem('pilar');
  }, []);

  // const enviar = e => {
  //   e.preventDefault();
  //   const id = uniqid();
  //   let json = {
  //     testimonio: testimonioForm.testimonio,
  //     numEmpleado: localStorage.getItem('0hNZeY/gjEeJihP9bIPGHw=='),
  //     aprobado: false
  //   }
  //   if (Object.keys(testimonioForm).length === 0) {
  //     Swal.fire({
  //       title: "Error",
  //       text: "El campo de testimonio esta vacio",
  //       icon: "error",
  //       confirmButtonText: "Cerrar",
  //       confirmButtonColor: "#2D6DB4",
  //     })
  //   } else {
  //     setData('testimonios', id, json).then(() => {
        
  //       Swal.fire({
  //         title: "¡Bien hecho!",
  //         text: "Tu testimonio se mando aprobación",
  //         icon: "success",
  //         confirmButtonText: "Cerrar",
  //         confirmButtonColor: "#2D6DB4",
  //       }).then((result) => {
  //         if (result.isConfirmed) {
  //           window.location.reload();
  //         }})
  //     }).catch((error) => {
  //       const errorCode = error.code;
  //       const errorMessage = error.message;
  //       console.log("Error #" + errorCode);
  //       console.log("Error " + errorMessage);

  //     })
  //   }
  //   // console.log(id);
  // }

  return (
    <>
      <ValidateUser>
        <Header />
        <div id="testimonios">
          <Container maxWidth="lg">
            <Grid container direction="row" alignItems="center" justifyContent="center" spacing={2}>
              <Grid item xs={12} sm={12} md={10} lg={10}>
                <h1>Testimonios</h1>
                <p>Conoce lo que tus colegas piensan sobre The PEOPLE Leader y cómo el programa les está ayudando con su liderazgo.</p>
              </Grid>
            </Grid>
          </Container>
          <div id="section-last-event" className="wow fadeInLeft" data-wow-delay='.2s' data-wow-offset='0' data-wow-duration='.5s'>
            <Container maxWidth="lg">
              <Grid container direction="row" alignItems="center" justifyContent="center" spacing={2}>
                <Grid item xs={12} sm={12} md={10} lg={10}>
                  <Carousel />
                </Grid>
              </Grid>
            </Container>
          </div>
        </div>
        {/* <div id='form-testimonio'>
          <Container maxWidth="lg">
            <Grid container direction="row" alignItems="center" justifyContent="center" spacing={2}>
              <Grid item xs={12} sm={12} md={10} lg={10}>
                <h1 className="color-verde">Dejanos tu testimonio</h1>
              </Grid>
              <Grid item xs={12} sm={12} md={10} lg={10}>
                <TextField
                  id="standard-textarea"
                  label="Escribe tu opinion"
                  placeholder=""
                  multiline
                  fullWidth
                  minRows={4}
                  maxRows={4}
                  variant="outlined"
                  onChange={e => setTestimonioForm({ ...testimonioForm, testimonio: e.target.value })}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={10} lg={10}>
                <Button className="btn " type="submit" onClick={enviar}>
                  Enviar
                </Button>
              </Grid>
            </Grid>
          </Container>
        </div> */}
        <Footer />
      </ValidateUser>
    </>
  );
}
